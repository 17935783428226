import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'How Do I Future-Proof My Retirement?',
    content: () => (
      <>
        We would recommend using our budgeting tool to give you some indication
        on ensuring you will have enough money for the day you retire. However,
        planning for your retirement is a complex process. Planning for
        retirement entails many non-monetary based decisions. Answering the
        following questions would be a good place to start: 1. What type of
        lifestyle do I want to live in retirement? 2. How much will this
        lifestyle cost per year? 3. How much do I currently have in my
        superannuation fund, and how much am I likely to have by retirement age?
        4. Do I have more than one superannuation fund and should I consolidate
        them? 5. Is it worth putting more into superannuation now, but still
        staying under the reasonable threshold? 6. Will I have any large debts
        outstanding? (i.e. mortgage, car etc.) 7. Will I be helping pay off
        someone else’s expenses? (i.e. daughter going through University etc.)
        8. Do I want to go on holidays periodically? 9. What other income
        sources will I have and how much will I earn from them? (ie investment
        property, owner of a business, shares etc.) Although it is possible to
        plan your retirement by yourself, we don’t recommend it. We suggest
        speaking to an adviser when it comes to something as important and
        complex as your retirement. An adviser will give you the clarity and
        peace of mind you need when making these critical life decisions.
      </>
    ),
  },
  {
    title: 'Is my data secure?',
    content: () => (
      <>
        You can be assured that your data is 100% safe with Advice Cyborg. We do
        not sell, distribute or share your data with any third-party.{' '}
      </>
    ),
  },
  {
    title: 'How do I invest with Advice Cyborg?',
    content: () => (
      <>
        It’s very simple - signing up only takes 5 minutes! If you are
        interested in our services, we will give you a questionnaire to
        understand more about you. We need to know things such as your current
        position, future goals and your risk tolerance – all of which will help
        us give you the right advice!
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
